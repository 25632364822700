import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { ApplicationLayout } from '../application';
import { Form, FormGroup, Label, Input, Row, Col, Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap'
import formStyles from '../../../components/styles/form.module.css'
import { navigate } from '@reach/router';
import { handleApplicationUpdate } from '../../../services/api';
import { isLoggedIn } from "../../../services/auth"
import validator from 'validator'

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class edit extends Component {


  render() {
    const data = !this.props.location.state ? '' :  this.props.location.state.data


    return (
        <ApplicationLayout location={this.props.location}>
            <ApplicationForm data={data}/>
        </ApplicationLayout>
    )
  }
}


export class ApplicationForm extends Component {
  constructor(props){
    super(props)

    const data = !this.props.data ?  null : this.props.data.data
    // console.log(data)

    this.state = {
      name : data ? data.name : null,
      description: "",
      rest : data ? (data.api.includes("/paymentservices/v2/payadvice") ? 1 : 0) : 0,
      qr : data ? (data.api.includes("/paymentservices/v2/payquery") ? 1 : 0) : 0,
      modal: false,
      username : user ? user.userData.attributes.name : "",
      id : user ? user.userData.username : "",

      descriptionValid : false,
      validForm: false,
      
      isLoading : false
    }

    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount(){
    if(this.state.name === null){
        navigate('/account/application/', {state: {} })
    }
    this.forceUpdate()
  }

  UNSAFE_componentWillUpdate(){
      if(this.state.name === null){
          navigate('/account/application/', {state: {} })
      }
  }

  handleChange = event => {
    const name = event.target.id;
    const value = event.target.value;
    this.setState({
        [name]: value
    }, () => {
      this.validateInput( name, value )
    });
  }

  validateInput(name, value) {
    let descriptionValid = this.state.descriptionValid

    descriptionValid = !validator.isEmpty(value)
    

    this.setState({
      descriptionValid : descriptionValid
    }, () => {
      this.validateForm()
    })
  }

  validateForm(){
    this.setState({
      validForm : this.state.descriptionValid
    })
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleSubmit = async event => {
    event.preventDefault()

    await handleApplicationUpdate(this.state)
    .then(() => {
      navigate('/account/application/')
    })
    .catch(err => console.log(err))
  }

  render() {
    return (
      <Row >
        <Col md={{size:6, offset:3}} className={formStyles.signupContainer}>
          <Col sm="12" md="12" >
            <Form>
              <h1 style={{textAlign: "center"}}>Edit Application</h1>
              <FormGroup>
                <Label>Application Name</Label>
                <Input
                  type="text"
                  id="name"
                  readOnly
                  defaultValue={this.state.name}
                />
              </FormGroup>
              <FormGroup>
                <Label>Description</Label>
                <Input
                  type="textarea"
                  id="description"
                  value={this.state.description}
                  onChange={this.handleChange}
                  required
                  title="Please fill up this field"
                />
              </FormGroup>

              <Button 
                color={!this.state.validForm || this.state.isLoading ? "secondary" : "primary"} 
                disabled={!this.state.validForm || this.state.isLoading} 
                block
              >
                UPDATE APPLICATION
              </Button>
              <Button color="link" block
                onClick={event=>{
                  event.preventDefault()
                  this.toggle()
                }}
              >
                Cancel
              </Button>

                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className}>
                  <ModalHeader toggle={this.toggle}>Confirmation</ModalHeader>
                  <ModalBody>
                    Are you sure you want to discard your changes?
                  </ModalBody>
                  <ModalFooter>
                    <Button
                      color="primary"
                      onClick={event => {
                        event.preventDefault()
                        navigate('/account/application/')
                        this.toggle()
                      }}>
                      Yes
                    </Button>{' '}
                    <Button color="secondary" onClick={this.toggle}>No</Button>
                  </ModalFooter>
                </Modal>
            </Form>
          </Col>
        </Col>
      </Row>
    )
  }
}

